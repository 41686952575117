import React, { useEffect, useState } from "react";
import EventsItem from "../Events/EventsItem";

import { getPastEvents } from "../../../scripts/dataHandlers";
import { useTranslation } from "react-i18next";

export default function PastEvents(props) {
	const [allPastEvents, setAllPastEvents] = useState(null);
	const { t } = useTranslation();

	const { limit } = props.settings;

	useEffect(() => {
		const runFunction = async () => {
			try {
				const res1 = await getPastEvents(props.organizationId, limit);

				if (res1.data.status === "ok") {
					setAllPastEvents(res1.data.events);
				}
			} catch (error) {
				console.log(error);
			}
		};

		props.organizationId && runFunction();
	}, [props.organizationId, limit]);
	return (
		// If it includes props(upcomingEventCategory) , it means it wont have any category id or name and we have to fetch filter data and route accordingly
		allPastEvents &&
		allPastEvents.length > 0 && (
			<EventsItem
				allEvents={allPastEvents}
				heading={t("eventsCategory.pastEventsTitle")}
				upcomingEventCategory={false}
				organizationId={props.organizationId}
				homepageContainer={props.homepageContainer}
				extraClassname={props.extraClassname}
				styles={props.styles}
				viewAllRoute={`/events/Past Events/${props.organizationId}/pastEvent`}
			/>
		)
	);
}
